<!-- #Developer by Halil Kılıçarslan -->

<template>
  <header class="page-footer">
    <slot name="left" />
    <v-spacer />
    <slot name="right" />
  </header>
</template>

<script>
  export default {
    name: "AppPageFooter",
    props: {
      loading: {
        type: Boolean,
        required: false,
        default: () => false
      },
      create: {
        type: Boolean,
        required: false,
        default: () => false
      },
      filter: {
        type: Boolean,
        required: false,
        default: () => false
      },
      update: {
        type: Boolean,
        required: false,
        default: () => false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: () => false
      }
    }
  };
</script>

<style scoped>
  .page-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-top: 1px solid #eee;
  }
</style>
